import { Req, Res, SiteInfo } from '@cbo/shared-library';
import { TFunction } from 'react-i18next';
import { formatDate, formatTime } from '../../utils';

export const formatAddress = (
  address: Req.SiteManagement.SiteAddressData | undefined,
  t: TFunction<'translation', undefined>
) => {
  if (!address) {
    return '-';
  }

  const { street, city, state, postalCode, country } = address;
  if (address.country === 'USA') {
    return t('admin.allSitesDatagrid.addressFormat', {
      street,
      city,
      state,
      postalCode,
    });
  }

  const addressParts = [street, city, state, postalCode, country].filter((value) => !!value);
  return addressParts.join(', ');
};

export const formatTimeStamp = (date: Date | string, preferences: Res.Admin.UserPreferences | null) => {
  if (!date) {
    return '-';
  }

  const formattedDate = formatDate(date, preferences);
  const formattedTime = formatTime(date, preferences);

  return `${formattedDate} ${formattedTime}`;
};

export const getSiteButtonText = (
  selectedSiteName: string,
  multiSelectedSites: SiteInfo[],
  allSitesLength: number,
  isSiteFiltering: boolean,
  isHeader: boolean,
  sitesText: string,
  allSitesText: string
) => {
  let buttonText = '';
  if (isHeader) {
    buttonText = selectedSiteName;
    if (isSiteFiltering && multiSelectedSites.length > 0)
      buttonText = `${multiSelectedSites.length} ${sitesText.toLowerCase()}`;
    if (isSiteFiltering && multiSelectedSites.length === 1 && multiSelectedSites[0].name)
      buttonText = multiSelectedSites[0].name;
    else if (isSiteFiltering && multiSelectedSites.length === allSitesLength) buttonText = allSitesText;
  } else {
    buttonText = `${multiSelectedSites.length} ${sitesText.toLowerCase()}`;
    if (multiSelectedSites.length === 1 && multiSelectedSites[0].name) buttonText = multiSelectedSites[0].name;
    if (multiSelectedSites.length === 0) buttonText = selectedSiteName;
    else if (multiSelectedSites.length === allSitesLength) buttonText = allSitesText;
  }
  return buttonText;
};
