import { Req } from '@cbo/shared-library';
import { FindSitesByCriteria } from '@cbo/shared-library/request/site-management.request';

/**
 * USER PREFERENCES
 */
const userPreferencesKeys = {
  preferences: () => ['userPreferences'] as const,
};

const dashboardConfigurationKeys = {
  dashboardConfiguration: () => ['userDashboardConfiguration'] as const,
};
/**
 * COMPANY LINKS
 */
const companyLinksKeys = {
  companyLinks: (organizationId?: string) => ['companyLinks', organizationId] as const,
  sideBarCompanyLinks: (organizationId?: string) => ['sideBarCompanyLinks', organizationId] as const,
};

/**
 * FISCAL CALENDAR
 */
const fiscalCalendarKeys = {
  fiscalCalendarById: (userId: string, organizationId?: string) => ['fiscalCalendar', userId, organizationId] as const,
  fiscalCalendarPreviewById: (userId: string, year: number) => ['fiscalCalendarPreview', userId, year] as const,
};

/**
 * PAYROLL CALENDAR
 */
const payrollCalendarKeys = {
  calendarBySiteId: (siteId: string | null) => (siteId ? ['payrollCalendar', siteId] : ['payrollCalendar']),
  filtersBySiteId: (siteId: string) => ['payrollCalendar', 'filters', siteId] as const,
};

const notificationSettingsKeys = {
  all: ['allNotificationSettings'] as const,
  allNotificationSettings: (organizationId?: string) => [...notificationSettingsKeys.all, organizationId] as const,
  notificationSettingsById: (notificationSettingsId: string) =>
    ['notificationSettingsById', notificationSettingsId] as const,
  allEventTypes: (organizationId?: string) => ['allEventTypes', organizationId] as const,
};

const notificationsKeys = {
  notifications: (keys: string[]) => [...keys] as const,
  totalUnread: () => ['totalUnreadNotifications'] as const,
};

/**
 * SITE MANAGEMENT
 */
const siteManagementKeys = {
  allSites: (siteIds?: string[], organizationId?: string) => ['allSites', siteIds, organizationId] as const,
  allSitesByCriteria: (criteria: FindSitesByCriteria) => ['allSitesByCriteria', criteria] as const,
  siteDetailsDataById: (siteId: string) => ['siteDetailsDataById', siteId] as const,
  siteDataByIds: (siteIds: string[]) => ['siteDataByIds', siteIds] as const,
};

/**
 * AUDIT LOG
 */
const auditLogKeys = {
  allAuditLogs: (filter: Req.AuditLog.FilterRequest, organizationId?: string) =>
    ['allAuditLogs', filter, organizationId] as const,
  allAuditLogFilters: (organizationId?: string) => ['allAuditLogFilters', organizationId] as const,
};

/**
 * GL Mapping
 */
const glMappingKeys = {
  allGlMappings: (dataTypes?: string[], entityNames?: string[], glAccountNames?: string[]) =>
    ['allGlMappings', dataTypes, entityNames, glAccountNames] as const,
  jobCodeMappings: (dataTypes?: string[], entityNames?: string[], glAccountNames?: string[]) =>
    ['jobCodeMappings', dataTypes, entityNames, glAccountNames] as const,
  glMappingFilters: ['glMappingFilters'] as const,
};

const adminKeys = {
  userPreferences: userPreferencesKeys,
  companyLinks: companyLinksKeys,
  fiscalCalendar: fiscalCalendarKeys,
  payrollCalendar: payrollCalendarKeys,
  notificationSettings: notificationSettingsKeys,
  notifications: notificationsKeys,
  siteManagement: siteManagementKeys,
  auditLog: auditLogKeys,
  glMapping: glMappingKeys,
  dashboardConfiguration: dashboardConfigurationKeys,
};

export default adminKeys;
