import { CboRole, FeatureFlag, Package, Req } from '@cbo/shared-library';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import 'dayjs/locale/es-mx';
import 'dayjs/locale/fr-ca';
import { changeLanguage } from 'i18next';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  Suspense,
  createContext,
  lazy,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Alert from '@mui/material/Alert';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useUserPreferencesContext, useUserProfileContext } from '@ncr-voyix-commerce/react-common-components';
import { convertRegionToDayJsLocale } from '../countryAndRegionData';
import NotificationsDialog from '../shared/admin/NotificationsDialog/NotificationsDialog';
import { NotificationPriority } from '../shared/admin/models/NotificationSettings';
import { proxies } from '../shared/admin/requests';
import { useCompanyLinksDataQuery, useTotalUnreadNotificationsQuery } from '../shared/admin/requests/queries';
import RequirePermission from '../shared/auth/RequirePermission/RequirePermission';
import AppBar from '../shared/components/AppBar/AppBar';
import NotificationRightPanel from '../shared/components/RightPanel/Notifications/NotificationsRightPanel/NotificationsRightPanel';
import AppSidebar from '../shared/components/SidebarNavigation/AppSidebar';
import { CustomV4Sidebar } from '../shared/components/SidebarNavigation/ncr-design-system-components/CustomSidebar/CustomSideBar';
import { useSidebar } from '../shared/components/SidebarNavigation/ncr-design-system-components/CustomSidebar/CustomSidebarContext';
import * as DemoComponents from '../shared/components/UxDemo/UxDemo';
import { NotFoundDemo } from '../shared/components/UxDemo/UxDemo';
import routes from '../shared/constants/routes';
import { useSnackbar } from '../shared/contexts/SnackbarContext';
import { useAccountPreferences, AccountPreferences } from '../shared/contexts/accountPreferencesContext';
import { useCompanyLinks } from '../shared/contexts/companyLinksContext';
import defaultPreferences from '../shared/contexts/defaultPreferences';
import { useNotifications } from '../shared/contexts/notificationContext';
import { useUsers } from '../shared/contexts/userContext';
import ErrorFallback from '../shared/errors/ErrorFallback';
import LaborRulesPage from '../shared/laborRules/LaborRulesPage/LaborRulesPage';
import { Nullable } from '../shared/models/Nullable';
import { useOrgContext } from '../shared/org/CommonComponentWrapper';
import useCallBsl from '../shared/utils/hooks/useCallBsl';
import { useFeatureFlags } from '../shared/utils/hooks/useFeatureFlag';
import usePrefetchReportData from '../shared/utils/hooks/usePrefetchReportData';
import { getStoredLang, setStoredLang } from '../shared/utils/langUtils/langUtils';
import PackagePermissionWrapper from '../shared/auth/PackagePermission/PackagePermission';
import { usePackages } from '../shared/contexts/packageContext';
import HomePage from '../shared/admin/HomePage/HomePage';
import LaborSettingsSchedulePage from '../shared/labor/LaborSettingsSchedulePage/LaborSettingsSchedulePage';

const Verb = Req.Firebase.HttpVerb;

export type RightPanels = 'notifications' | 'laborPanel';

interface ShellProps {
  apiRoot: string;
}

const AuditActivityLoggingPage = lazy(
  () => import('../shared/admin/AuditActivityLoggingPage/AuditActivityLoggingPage')
);
const JobsPage = lazy(() => import('../shared/labor/Jobs/JobsPage'));
const JobsFocusMode = lazy(() => import('../shared/labor/JobFocusMode/JobFocusMode'));
const EmployeeRegistrationPage = lazy(
  () => import('../shared/labor/EmployeeRegistrationPage/EmployeeRegistrationPage')
);
const DayPartsPage = lazy(() => import('../shared/admin/DayParts/DayPartsPage'));
const EditLinksPage = lazy(() => import('../shared/admin/EditLinksPage/EditLinksPage'));
const FiscalCalendarPage = lazy(() => import('../shared/admin/FiscalCalendarPage/FiscalCalendarPage'));
const NotificationSettingsPage = lazy(() => import('../shared/admin/NotificationSettings/NotificationSettingsPage'));
const PayrollCalendarPage = lazy(() => import('../shared/admin/PayrollCalendarPage/PayrollCalendarPage'));
const SitesPage = lazy(() => import('../shared/admin/Sites/SitesPage'));
const SiteSettingsPage = lazy(() => import('../shared/admin/SiteSettingsPage/SiteSettingsPage'));
const InventoryHomePage = lazy(() => import('../shared/inventory/InventoryHome'));
const LaborHomePage = lazy(() => import('../shared/labor/LaborHome'));
const MySchedulePage = lazy(() => import('../shared/labor/Scheduling/MySchedulePage/MySchedulePage'));
const SalesHomePage = lazy(() => import('../shared/sales/SalesHome'));
const SalesSettingsHomePage = lazy(() => import('../shared/sales/SalesSettings/SalesSettingsHome'));
const PlaceholderPage = lazy(() => import('./PlaceholderPage'));
const GeneralLedgerAccountsDashboard = lazy(
  () => import('../shared/sales/GeneralLedgerAccounts/GeneralLedgerAccountsDashboard')
);
const GeneralLedgerAccountMappingPage = lazy(
  () => import('../shared/admin/GeneralLedgerAccountMapping/GeneralLedgerAccountMappingPage')
);

type ShellContextValue = {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  openRightPanel: Nullable<RightPanels>;
  setRightPanel: Dispatch<SetStateAction<Nullable<RightPanels>>>;
};

export const ShellContext = createContext<ShellContextValue>({
  sidebarOpen: true,
  setSidebarOpen: () => {
    // throw new Error('Not implemented');
  },
  openRightPanel: null,
  setRightPanel: () => {
    // throw new Error('Not implemented');
  },
});

function ShellContextProvider({ children }: { children: ReactNode }) {
  const { setCollapsed } = useSidebar();
  const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(true);
  const [openRightPanel, setRightPanel] = React.useState<Nullable<RightPanels>>(null);
  const { pathname: currentUrl } = useLocation();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  usePrefetchReportData();

  useEffect(() => {
    // Close sidebar when we click into focus mode
    if (
      [
        routes.EMPLOYEE_OVERVIEW_FOCUSMODE_BASE,
        routes.HOUSE_ACCOUNT_FOCUSMODE_BASE,
        routes.JOBCODE_CONFIGURATION_FOCUSMODE_BASE,
      ].some((focusModePath) => currentUrl.includes(focusModePath))
    ) {
      setCollapsed(false);
    }
  }, [currentUrl, isMobile, setCollapsed]);

  const value = useMemo(
    () => ({
      isMobile,
      sidebarOpen,
      setSidebarOpen,
      openRightPanel,
      setRightPanel,
    }),
    [isMobile, sidebarOpen, setSidebarOpen, openRightPanel, setRightPanel]
  );

  return <ShellContext.Provider value={value}>{children}</ShellContext.Provider>;
}
export const useShell = () => useContext(ShellContext);

function Routes() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [cboLaborRulesEnabled, cboGeneralLedgerAccounts, cboWorkWeekConfig, cboHideAlohaEssentialOrgs] =
    useFeatureFlags(
      FeatureFlag.laborRules,
      FeatureFlag.generalLedgerAccounts,
      FeatureFlag.workWeekConfig,
      FeatureFlag.hideAlohaEssentialOrgs
    );

  return (
    <Switch>
      <Route exact path={routes.HOME_PAGE} key='home-page'>
        <HomePage />
      </Route>
      <Route exact path={routes.DEMO_ITEMS} key='demo-items'>
        <DemoComponents.Page />
      </Route>
      <Route path={routes.NOT_AUTHORIZED} key='not-authorized'>
        <PlaceholderPage contentText='Not authorized' dataTestId='not-authorized-page' />
      </Route>
      <Route path={routes.EMPLOYEE} key='employee'>
        <PlaceholderPage contentText='Employee Page' dataTestId='employee-page' />
      </Route>
      <Route path={routes.MANAGER} key='manager'>
        <PlaceholderPage contentText='Manager Page' dataTestId='manager-page' />
      </Route>
      <Route path={routes.NOTIFICATION_SETTINGS} key='notifications-settings'>
        <RequirePermission permissions={[CboRole.NOTIFICATION_GROUP_EDIT]}>
          <NotificationSettingsPage />
        </RequirePermission>
      </Route>
      <Route path={routes.EMPLOYEE_REGISTRATION_HOME} key='employee-registration'>
        <RequirePermission permissions={[CboRole.EMPLOYEE_EDIT, CboRole.EMPLOYEE_PERSONAL_EDIT]}>
          <EmployeeRegistrationPage />
        </RequirePermission>
      </Route>
      <Route path={routes.EDIT_COMPANY_LINKS} key='edit-company-links'>
        <RequirePermission permissions={[CboRole.LINK_ADMIN]}>
          <EditLinksPage />
        </RequirePermission>
      </Route>
      <Route path={routes.SITE_SETTINGS} key='site-settings'>
        <RequirePermission permissions={[CboRole.SITE_VIEW, CboRole.SITE_EDIT]}>
          <SiteSettingsPage />
        </RequirePermission>
      </Route>
      <Route path={routes.FISCAL_CALENDAR} key='fiscal-calendar'>
        <RequirePermission permissions={[CboRole.FISCAL_CALENDAR_VIEW, CboRole.FISCAL_CALENDAR_EDIT]}>
          <FiscalCalendarPage />
        </RequirePermission>
      </Route>
      {!cboHideAlohaEssentialOrgs && (
        <Route path={routes.PAYROLL_CALENDAR} key='payroll-calendar'>
          <RequirePermission permissions={[CboRole.PAYROLL_CALENDAR_VIEW, CboRole.PAYROLL_CALENDAR_EDIT]}>
            <PayrollCalendarPage />
          </RequirePermission>
        </Route>
      )}
      <Route path={routes.SITES} key='sites'>
        <RequirePermission permissions={[CboRole.SITE_VIEW, CboRole.SITE_EDIT]}>
          <SitesPage />
        </RequirePermission>
      </Route>
      <Route path={routes.DAY_PARTS} key='day-parts'>
        <PackagePermissionWrapper packagesRequired={[Package.STARTER]}>
          <RequirePermission permissions={[CboRole.SITE_DAY_PART_VIEW, CboRole.SITE_DAY_PART_EDIT]}>
            <DayPartsPage />
          </RequirePermission>
        </PackagePermissionWrapper>
      </Route>
      <Route path={routes.ACTIVITY_LOG} key='activity-log'>
        <RequirePermission permissions={[CboRole.AUDIT_LOG_VIEW]}>
          <AuditActivityLoggingPage />
        </RequirePermission>
      </Route>
      {!cboHideAlohaEssentialOrgs && cboLaborRulesEnabled && (
        <Route path={routes.LABOR_RULES} key='labor-rules'>
          <PackagePermissionWrapper packagesRequired={[Package.LABOR_PAYROLL]}>
            <RequirePermission permissions={[CboRole.LRE_RULE_WRITER, CboRole.LRE_RULE_EXEC]}>
              <LaborRulesPage />
            </RequirePermission>
          </PackagePermissionWrapper>
        </Route>
      )}
      {/* once roles are created those roles should be applied to laborSettingsSchedule */}
      {!cboHideAlohaEssentialOrgs && cboWorkWeekConfig && (
        <Route path={routes.LABOR_SETTINGS_SCHEDULE} key='labor-settings-schedule'>
          <PackagePermissionWrapper packagesRequired={[Package.STARTER]}>
            <LaborSettingsSchedulePage />
          </PackagePermissionWrapper>
        </Route>
      )}
      {!cboHideAlohaEssentialOrgs && (
        <Route path={routes.JOBCODE_CONFIGURATION_DETAILS} key='jobcode-configuration-details'>
          <RequirePermission permissions={[CboRole.JOBCODE_CONFIG_EDIT]}>
            <JobsFocusMode />
          </RequirePermission>
        </Route>
      )}

      {!cboHideAlohaEssentialOrgs && (
        <Route path={routes.JOBCODE_EMPLOYEES_OVERVIEW_PAGE} key='jobcode-employee-overview'>
          <RequirePermission permissions={[CboRole.JOBCODE_CONFIG_VIEW]}>
            <JobsFocusMode />
          </RequirePermission>
        </Route>
      )}

      {!cboHideAlohaEssentialOrgs && (
        <Route path={routes.JOBCODE_CONFIGURATION_PAGE} key='jobcode-configuration'>
          <RequirePermission permissions={[CboRole.JOBCODE_CONFIG_VIEW]}>
            <JobsPage />
          </RequirePermission>
        </Route>
      )}

      {cboGeneralLedgerAccounts && [
        <Route key='gl-dash' path={routes.MANAGE_GL_ACCOUNTS}>
          <PackagePermissionWrapper packagesRequired={[Package.ADVANCED_ANALYTICS]}>
            <RequirePermission permissions={[CboRole.GL_ACCOUNTS_READ]}>
              <GeneralLedgerAccountsDashboard />
            </RequirePermission>
          </PackagePermissionWrapper>
        </Route>,
        <Route key='gl-map' path={routes.GL_ACCOUNT_MAPPING}>
          <PackagePermissionWrapper packagesRequired={[Package.ADVANCED_ANALYTICS]}>
            <RequirePermission permissions={[CboRole.GL_ACCOUNTS_READ]}>
              <GeneralLedgerAccountMappingPage />
            </RequirePermission>
          </PackagePermissionWrapper>
        </Route>,
      ]}
      <Route path={routes.SALES_SETTINGS} key='sales-settings'>
        <SalesSettingsHomePage />
      </Route>
      {!cboHideAlohaEssentialOrgs && (
        <Route path={routes.LABOR_MODULE} key='labor'>
          <LaborHomePage />
        </Route>
      )}
      <Route path={routes.INVENTORY_MODULE} key='inventory'>
        <InventoryHomePage />
      </Route>
      <Route path={routes.SALES} key='sales'>
        <SalesHomePage />
      </Route>
      <Route path={routes.MY_SCHEDULE} key='my-schedule'>
        <RequirePermission permissions={[CboRole.SCHEDULE_PERSONAL_VIEW]}>
          <MySchedulePage isMobile={isMobile} />
        </RequirePermission>
      </Route>
      <Route path='*' key='not-found'>
        <NotFoundDemo />
      </Route>
    </Switch>
  );
}

export function OldShell(props: ShellProps) {
  const { apiRoot } = props;
  const { t } = useTranslation();
  const callBsl = useCallBsl();
  const { setRightPanel, openRightPanel } = useShell();
  const handleRightPanelClose = () => setRightPanel(null);
  const user = useUsers();
  const { setSnackbarState } = useSnackbar();
  const { setSidebarLinks } = useCompanyLinks();
  const notificationContext = useNotifications();
  const { organization } = useOrgContext();
  const { packagesSet } = usePackages();
  const { userPreferences } = useUserPreferencesContext();
  const { setPreferences } = useAccountPreferences();
  const { userProfile } = useUserProfileContext();
  const [isLoadingTokenExchange, setIsLoadingTokenExchange] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [notificationsTotalUnread, setNotificationsTotalUnread] = useState(0);
  const [notificationsToRender, setNotificationsToRender] = useState(0);
  const [notificationsDialogOpen, setNotificationsDialogOpen] = useState(false);
  const [adapterLocale, setAdapterLocale] = React.useState('en');
  const [notificationAlert, setNotificationAlert] = React.useState(false);
  const { pathname: currentUrl } = useLocation();
  const notificationBatchSize = 5;

  const { data: totalUnreadNotifications, isError: isGetNotificationError } = useTotalUnreadNotificationsQuery(
    user.fullyAuthenticated === 'authenticated'
  );

  useEffect(() => {
    if (isGetNotificationError && ['/'].includes(currentUrl)) {
      setNotificationAlert(true);
    } else {
      setNotificationAlert(false);
    }
  }, [isGetNotificationError, currentUrl]);

  useEffect(() => {
    if (totalUnreadNotifications?.totalUnread !== undefined) {
      setNotificationsTotalUnread(totalUnreadNotifications.totalUnread);
    }
  }, [totalUnreadNotifications]);

  const handleNotificationsDialogClose = () => {
    setNotificationsDialogOpen(false);
  };

  const handleNotificationsTotalUnread = (totalUnread: number) => {
    setNotificationsTotalUnread(totalUnread);
  };

  const handleNotificationsOpen = () => {
    handleRightPanelClose();
    setNotificationsDialogOpen(true);
  };

  const preferencesToSet: AccountPreferences = useMemo(
    () =>
      userPreferences && Object.keys(userPreferences).length > 0
        ? // User preferences from BSL now contain region and language but it will need to be mapped to correct key on our side for it to work. Currently just making sure it defaults to proper region
          {
            ...defaultPreferences,
            ...userPreferences,
            region: defaultPreferences.region,
            language: defaultPreferences.language,
            phoneNumber: userProfile?.telephoneNumber ?? '',
          } // Currently considering default values for language,region,showCurrencySymbol and dontShowAgain
        : defaultPreferences,
    [userPreferences, userProfile]
  );

  const usingDefaultPreferences = !(userPreferences && Object.keys(userPreferences).length > 0);

  // Get Company Links
  const {
    data: sidebarLinksData,
    isLoading: isSidebarLinksLoading,
    isError: isGetSidebarLinksError,
  } = useCompanyLinksDataQuery(user.fullyAuthenticated === 'authenticated', true);

  // Process Okta state login
  useEffect(() => {
    setIsLoadingTokenExchange(user.oktaStatus === 'authenticated' && user.firebaseStatus !== 'authenticated');
  }, [user.oktaStatus, user.firebaseStatus, user.fullyAuthenticated]); // Update if oktaAuthState is authenticated changes

  useEffect(() => {
    async function sendFCMToken(fcmToken: string): Promise<void> {
      await callBsl({
        proxy: proxies.notifications,
        verb: Verb.POST,
        pathSegments: ['fcm-token'],
        payload: {
          fcmToken,
        },
      });
    }

    if (user.fcmToken) {
      sendFCMToken(user.fcmToken);
    }
  }, [user.fcmToken, apiRoot, callBsl]);

  // Setting the language
  useEffect(() => {
    async function setLanguageFromPreferences(): Promise<void> {
      if (user.fullyAuthenticated === 'authenticated') {
        if (!usingDefaultPreferences) {
          await changeLanguage(preferencesToSet.language);
          setStoredLang(preferencesToSet.language);
        } else {
          await changeLanguage(getStoredLang());
        }
        setPreferences(preferencesToSet);
      }
    }
    setLanguageFromPreferences();
  }, [preferencesToSet, usingDefaultPreferences, preferencesToSet.language, setPreferences, user?.fullyAuthenticated]);

  useEffect(() => {
    const formattedLocale = convertRegionToDayJsLocale(preferencesToSet.region);
    setAdapterLocale(formattedLocale);
  }, [preferencesToSet]);

  useEffect(() => {
    if (!isSidebarLinksLoading && user.fullyAuthenticated === 'authenticated') {
      if (isGetSidebarLinksError) {
        setSnackbarState({ open: true, message: `Error fetching company links`, color: 'error' });
      }
      setSidebarLinks(sidebarLinksData ?? null);
    }
  }, [sidebarLinksData, isSidebarLinksLoading, setSidebarLinks, user, isGetSidebarLinksError, setSnackbarState]);

  useEffect(() => {
    if (isGetNotificationError && ['/'].includes(currentUrl)) {
      setNotificationAlert(true);
    } else {
      setNotificationAlert(false);
    }
  }, [isGetNotificationError, currentUrl]);

  useEffect(() => {
    if (notificationContext.broadcastChannel === null) {
      return;
    }
    notificationContext.broadcastChannel.onmessage = (payload) => {
      notificationContext.setNewNotification(payload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationContext.broadcastChannel, notificationContext.setNewNotification, user.fullyAuthenticated]);

  useEffect(() => {
    if (notificationContext.newNotification === null) {
      return;
    }
    setSnackbarState({
      open: true,
      message: notificationContext.newNotification.data?.description,
      color: 'info',
      notificationPriority:
        notificationContext.newNotification.data?.priority === 'true'
          ? NotificationPriority.Priority
          : NotificationPriority.Normal,
    });
  }, [notificationContext.newNotification, setSnackbarState, user.fullyAuthenticated]);

  useEffect(() => {
    const headerAndFooterHeightInPx = 200;
    const clientHeight = window.innerHeight;
    const availableHeight = clientHeight - headerAndFooterHeightInPx;
    const listItemHeight = isMobile ? 131 : 128;
    setNotificationsToRender(Math.floor(availableHeight / listItemHeight));
  }, [setNotificationsToRender, isMobile]);

  useEffect(() => {
    sessionStorage.setItem('showLogin', 'false');
  }, []);
  return (
    <CustomV4Sidebar
      side='left'
      width={240}
      content={<AppSidebar />}
      slotProps={{
        sidebar: {
          sx: { zIndex: 1200 },
        },
      }}
    >
      <Drawer
        anchor='right'
        open={!!openRightPanel}
        onClose={() => setRightPanel(null)}
        PaperProps={{
          sx: {
            width: isMobile ? '100vw' : 320,
          },
        }}
      >
        <>
          {openRightPanel === 'notifications' && (
            <NotificationRightPanel
              isMobile={isMobile}
              handleNotificationsOpen={handleNotificationsOpen}
              handleRightPanelClose={handleRightPanelClose}
              notificationsToRender={notificationsToRender}
              handleNotificationsTotalUnread={handleNotificationsTotalUnread}
            />
          )}
          {openRightPanel === 'laborPanel' && <DemoComponents.RightPanelSecondary />}
        </>
      </Drawer>
      <AppBar hasUnreadNotifications={notificationsTotalUnread > 0} />
      <Box>
        {notificationAlert && (
          <Grid item xs={12} pb={2} pl={4} pr={4}>
            <Alert data-testid='fetch-error-alert' severity='error'>
              {t('admin.allNotificationsDialog.errorFetchingNotification')}
            </Alert>
          </Grid>
        )}

        {isLoadingTokenExchange || user.isOrgSwitching || !organization || !packagesSet ? (
          <Grid container alignItems='center' justifyContent='center' data-testid='spinner-container'>
            <CircularProgress />
          </Grid>
        ) : (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
            <Suspense
              fallback={
                <Grid container alignItems='center' justifyContent='center' data-testid='spinner-container'>
                  <CircularProgress />
                </Grid>
              }
            >
              <Routes />
            </Suspense>
          </LocalizationProvider>
        )}
        {notificationsDialogOpen && (
          <NotificationsDialog
            open
            isFullScreen={isMobile}
            notificationsToRender={notificationsToRender}
            notificationBatchSize={notificationBatchSize}
            handleNotificationsDialogClose={handleNotificationsDialogClose}
            handleNotificationsTotalUnread={handleNotificationsTotalUnread}
          />
        )}
      </Box>
    </CustomV4Sidebar>
  );
}

export default function Shell(props: ShellProps) {
  const { apiRoot } = props;
  const history = useHistory();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // on page error navigate home since it can cause an auth loop with the reset otherwise
        history.push('/');
      }}
    >
      <ShellContextProvider>
        <OldShell apiRoot={apiRoot} />
      </ShellContextProvider>
    </ErrorBoundary>
  );
}
