import { Req, Res } from '@cbo/shared-library';
import { SiteViewData } from '@cbo/shared-library/response/site-management.response';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import staleTimeSettings from '../../config/reactQueryConfig';
import { MessageResponse } from '../models/MessageResponse';
import {
  GetAllGlMappingsRequest,
  GlMappingFilterResponse,
  GlMappingsResponse,
  useAdminRequests,
  NotificationResponse,
  TotalUnreadNotifications,
} from './index';
import queryKeys from './queryKeys';

/**
 * USER PREFERENCES
 */
export const useUserDashboardConfigurationDataQuery = (
  isUserAuthenticated: boolean
): UseQueryResult<Res.Admin.DashboardConfigurationResponse> => {
  const { getUserDashboardConfigurationData } = useAdminRequests();

  return useQuery(
    queryKeys.dashboardConfiguration.dashboardConfiguration(),
    () => getUserDashboardConfigurationData(),
    {
      enabled: isUserAuthenticated,
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
    }
  );
};

/**
 * COMPANY LINKS
 */
export const useCompanyLinksDataQuery = (
  isUserAuthenticated: boolean,
  filterByUserRoles?: boolean,
  organizationId?: string
): UseQueryResult<Res.Firebase.Link[]> => {
  const { getAllCompanyLinks } = useAdminRequests();

  return useQuery(
    filterByUserRoles
      ? queryKeys.companyLinks.sideBarCompanyLinks(organizationId)
      : queryKeys.companyLinks.companyLinks(organizationId),
    async () => getAllCompanyLinks(filterByUserRoles),
    {
      enabled: isUserAuthenticated,
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
    }
  );
};

/**
 * FISCAL CALENDAR
 */
export const useFiscalCalendarDataQuery = (
  isUserAuthenticated: boolean,
  userId: string,
  organizationId?: string
): UseQueryResult<Res.Calendar.FiscalCalendar> => {
  const { getFiscalCalendarData } = useAdminRequests();

  return useQuery(queryKeys.fiscalCalendar.fiscalCalendarById(userId, organizationId), () => getFiscalCalendarData(), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

/**
 * PAYROLL CALENDAR
 */
export const usePayrollCalendarDataQuery = (
  isUserAuthenticated: boolean,
  siteId: string
): UseQueryResult<Res.Calendar.PayrollCalendar | MessageResponse> => {
  const { getPayrollCalendarData } = useAdminRequests();

  return useQuery(queryKeys.payrollCalendar.calendarBySiteId(siteId), async () => getPayrollCalendarData(siteId), {
    enabled: !!isUserAuthenticated && !!siteId,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

export const useAllNotificationSettingsDataQuery = (
  isUserAuthenticated: boolean,
  organizationId?: string
): UseQueryResult<Res.Notifications.NotificationSettings[]> => {
  const { getAllNotificationSettingsData } = useAdminRequests();

  return useQuery(
    queryKeys.notificationSettings.allNotificationSettings(organizationId),
    async () => getAllNotificationSettingsData(),
    {
      enabled: isUserAuthenticated,
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
    }
  );
};

export const useNotificationSettingsDataQuery = (
  isUserAuthenticated: boolean,
  notificationSettingsId: string
): UseQueryResult<Res.Notifications.NotificationSettings> => {
  const { getNotificationSettingsData } = useAdminRequests();

  return useQuery(
    queryKeys.notificationSettings.notificationSettingsById(notificationSettingsId),
    async () => getNotificationSettingsData(notificationSettingsId),
    {
      enabled: isUserAuthenticated,
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
    }
  );
};

export const useAllEventTypesDataQuery = (
  isUserAuthenticated: boolean,
  organizationId?: string
): UseQueryResult<{ data: Res.Notifications.EventType[] }> => {
  const { getAllEventTypesData } = useAdminRequests();

  return useQuery(queryKeys.notificationSettings.allEventTypes(organizationId), async () => getAllEventTypesData(), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

export const useNotificationsDataQuery = (
  isUserAuthenticated: boolean,
  pageNumber: number,
  pageSize: number,
  keys = ['notifications']
): UseQueryResult<NotificationResponse> => {
  const { getNotificationsData } = useAdminRequests();
  return useQuery(queryKeys.notifications.notifications(keys), async () => getNotificationsData(pageNumber, pageSize), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
    cacheTime: 0,
  });
};

export const useTotalUnreadNotificationsQuery = (
  isUserAuthenticated: boolean
): UseQueryResult<TotalUnreadNotifications> => {
  const { getTotalUnreadNotifications } = useAdminRequests();
  return useQuery(queryKeys.notifications.totalUnread(), async () => getTotalUnreadNotifications(), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.LOW,
    useErrorBoundary: false,
  });
};

/**
 * Site Management
 */
export const useAllSitesDataQuery = (
  isUserAuthenticated: boolean,
  siteIds?: string[],
  organizationId?: string,
  useErrorBoundary = false
): UseQueryResult<Res.SiteManagement.PagedResponse<SiteViewData>> => {
  const { getAllSitesData } = useAdminRequests();
  return useQuery(queryKeys.siteManagement.allSites(siteIds, organizationId), async () => getAllSitesData(siteIds), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary,
  });
};

export const useSiteDataByIdQuery = (
  isUserAuthenticated: boolean,
  siteId: string
): UseQueryResult<Res.SiteManagement.SiteViewData> => {
  const { getSiteDetailsDataById } = useAdminRequests();

  return useQuery(queryKeys.siteManagement.siteDetailsDataById(siteId), async () => getSiteDetailsDataById(siteId), {
    enabled: !!isUserAuthenticated && !!siteId,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

export const useSiteDataByIdsQuery = (
  isUserAuthenticated: boolean,
  siteIds: string[]
): UseQueryResult<Res.SiteManagement.SiteViewData[]> => {
  const { getSiteDetailsDataByIds } = useAdminRequests();

  return useQuery(queryKeys.siteManagement.siteDataByIds(siteIds), async () => getSiteDetailsDataByIds(siteIds), {
    enabled: isUserAuthenticated && !!siteIds.length,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

/**
 * Audit Log
 */
export const useAllAuditLogDataQuery = (
  isUserAuthenticated: boolean,
  filter: Req.AuditLog.FilterRequest,
  organizationId?: string
): UseQueryResult<Res.AuditLog.LogEntry[]> => {
  const { getAllAuditLogData } = useAdminRequests();
  return useQuery(queryKeys.auditLog.allAuditLogs(filter, organizationId), async () => getAllAuditLogData(filter), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

export const useAllAuditLogFilterQuery = (
  isUserAuthenticated: boolean,
  organizationId?: string
): UseQueryResult<Res.AuditLog.FilterResponse> => {
  const { getAllAuditLogFilters } = useAdminRequests();
  return useQuery(queryKeys.auditLog.allAuditLogFilters(organizationId), async () => getAllAuditLogFilters(), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

/**
 * GL Mapping
 */
export const useGetAllJobCodeMappings = (body: GetAllGlMappingsRequest): UseQueryResult<GlMappingsResponse[]> => {
  const { getAllJobCodeMappings } = useAdminRequests();

  return useQuery(
    queryKeys.glMapping.jobCodeMappings(body?.dataTypes, body?.entityNames, body?.glAccountNames),
    async () => getAllJobCodeMappings(body),
    {
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
    }
  );
};

export const useGetGlMappingFiltersQuery = (): UseQueryResult<GlMappingFilterResponse> => {
  const { getGlMappingFilters } = useAdminRequests();

  return useQuery(queryKeys.glMapping.glMappingFilters, async () => getGlMappingFilters(), {
    staleTime: staleTimeSettings.HIGH,
    useErrorBoundary: false,
  });
};

export const useGetAllGlMappings = (
  enabled: boolean,
  body: GetAllGlMappingsRequest
): UseQueryResult<GlMappingsResponse[]> => {
  const { getAllGlMappings } = useAdminRequests();

  return useQuery(
    queryKeys.glMapping.allGlMappings(body?.dataTypes, body?.entityNames, body?.glAccountNames),
    () => getAllGlMappings(body),
    {
      enabled,
    }
  );
};
