import DomainIcon from '@mui/icons-material/Domain';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Settings from '@mui/icons-material/Settings';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  ApplicationSwitcher,
  UserProfileOption,
  useRccContext,
  RccEventKeys,
} from '@ncr-voyix-commerce/react-common-components';
import { CboRole } from '@cbo/shared-library';
import UserProfileSettings from '../../org/UserProfile';
import { useShell } from '../../../pages/Shell';
import SiteSelector from '../../admin/SiteSelector/SiteSelector';
import routes from '../../constants/routes';
import { useUsers } from '../../contexts/userContext';
import { useOrgContext } from '../../org/CommonComponentWrapper';
import OrganizationSwitcher from '../../org/OrganizationSwitcher';
import { useSidebar } from '../SidebarNavigation/ncr-design-system-components/CustomSidebar/CustomSidebarContext';
import { loginButton, menuIcon, notificationsIcon, authenticatedActions, outerBox } from './styles';
import isPermitted from '../../lib/permissions';
import recordLogoutEvent from '../../services/AuditService';
import { useSites } from '../../contexts/siteContext';

interface AppBarProps {
  hasUnreadNotifications: boolean;
}

export const APPBAR_HEIGHT = '72px';

/** The content that will go in the app bar */
function AppBar({ hasUnreadNotifications }: AppBarProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setCollapsed, isCollapsed } = useSidebar();
  const { t } = useTranslation();
  const { setRightPanel } = useShell();
  const { pathname: currentUrl } = useLocation();
  const user = useUsers();
  const [isOrgView, setIsOrgView] = useState(false);
  const [isDrillDownView, setIsDrillDownView] = useState(false);
  const { organization, userOrganizations } = useOrgContext();
  const { isAuthenticated, events } = useRccContext();
  const { selectedSite } = useSites();

  useEffect(() => {
    const orgRoutes: string[] = [
      routes.SITES,
      routes.FISCAL_CALENDAR,
      routes.ACCOUNT_PREFERENCES,
      routes.NOTIFICATION_SETTINGS,
      routes.ACTIVITY_LOG,
      routes.LABOR_RULES,
      routes.INVOICE_HISTORY_REPORT,
      routes.UNFINALIZED_GL_ACCOUNTS_REPORT,
      routes.UNFINALIZED_VENDORS_REPORT,
      routes.GL_ACCOUNT_MAPPING,
      routes.MANAGE_GL_ACCOUNTS,
      routes.HOUSE_ACCOUNTS,
      routes.SALES_DEFINITIONS_SETTINGS,
      routes.LABOR_SETTINGS_SCHEDULE,
      routes.JOBCODE_CONFIGURATION_PAGE,
    ];

    if (orgRoutes.some((route) => currentUrl.includes(route))) {
      setIsOrgView(true);
    } else {
      setIsOrgView(false);
    }

    if (
      [
        routes.EMPLOYEE_OVERVIEW_FOCUSMODE_BASE,
        routes.HOUSE_ACCOUNT_FOCUSMODE_BASE,
        routes.JOBCODE_CONFIGURATION_FOCUSMODE_BASE,
        routes.JOBCODE_EMPLOYEES_FOCUSMODE_BASE,
      ].some((focusModePath) => currentUrl.includes(focusModePath) && currentUrl.split(focusModePath)[1].length <= 1)
    ) {
      setIsDrillDownView(false);
    } else if (
      [
        routes.EMPLOYEE_PAGE,
        routes.JOBCODE_CONFIGURATION_PAGE,
        routes.INVOICES,
        routes.VENDORS,
        routes.RAW_ITEMS,
        routes.HOUSE_ACCOUNTS,
        routes.NOTIFICATION_SETTINGS,
        routes.LABOR_RULES,
      ].some(
        (baseRoutes) =>
          currentUrl.includes(routes.EMPLOYEE_REGISTRATION_HOME) ||
          (currentUrl.includes(baseRoutes) && currentUrl.split(baseRoutes)[1].length > 1)
      )
    ) {
      setIsDrillDownView(true);
    } else {
      setIsDrillDownView(false);
    }
  }, [currentUrl]);

  useEffect(() => {
    const logoutCallback = () => {
      recordLogoutEvent({
        name: user.profile?.name ?? 'unknown',
        email: user.profile?.email ?? 'unknown',
        siteId: selectedSite.enterpriseUnitId,
        organizationId: user.org?.bslId ?? 'unknown',
      });
    };

    if (isAuthenticated && user.profile && user.org && selectedSite.enterpriseUnitId) {
      events.on(RccEventKeys.SIGNOUT, logoutCallback);
    }

    return () => {
      events.off(RccEventKeys.SIGNOUT, logoutCallback);
    };
  }, [isAuthenticated, events, user.profile, user.org, selectedSite.enterpriseUnitId]);

  const toggleSidebar = (): void => {
    setCollapsed((currentState) => !currentState);
  };

  const { oktaAuth } = useOktaAuth();
  const login = async () => {
    oktaAuth.signInWithRedirect();
  };

  const userProfileSettingsOptions: UserProfileOption[] = [];
  if (isPermitted(user, [CboRole.NOTIFICATION_GROUP_EDIT])) {
    userProfileSettingsOptions.push({
      icon: <Settings />,
      label: t('admin.notificationSettingsPage.notificationSettingsTitle'),
      url: routes.NOTIFICATION_SETTINGS,
    });
  }

  return (
    <Box sx={outerBox(theme)}>
      {(isMobile || isCollapsed) && process.env.REACT_APP_USE_PREVIEW_ORG_SWITCHER !== 'true' ? (
        <ApplicationSwitcher isDisplayMode onClick={toggleSidebar} data-testid='app-switcher' />
      ) : (
        <IconButton
          onClick={toggleSidebar}
          data-testid='toggle-sidebar'
          name='sidebar-menu'
          aria-label='Toggle Navigation Menu'
          className={!isCollapsed ? 'open-sidebar' : 'sidebar'}
        >
          <MenuIcon sx={menuIcon} />
        </IconButton>
      )}

      {user.oktaStatus === 'authenticated' && (
        <Box sx={authenticatedActions(isMobile)} data-testid='authenticated-actions'>
          {userOrganizations?.length !== 1 && !isDrillDownView ? (
            <OrganizationSwitcher />
          ) : (
            <span>
              <Button disabled data-testid='drill-down-view-btn' startIcon={<DomainIcon />}>
                {organization?.displayName}
              </Button>
            </span>
          )}
          {!user.isOrgSwitching && organization && <SiteSelector isOrgView={isOrgView} />}
          <UserProfileSettings inputOptions={userProfileSettingsOptions} />
          <IconButton aria-label='Notifications' sx={notificationsIcon} onClick={() => setRightPanel('notifications')}>
            <Badge color='primary' variant='dot' invisible={!hasUnreadNotifications}>
              <NotificationsIcon data-testid='notifications-icon' />
            </Badge>
          </IconButton>
        </Box>
      )}

      {user.oktaStatus === 'unauthenticated' && (
        <Button variant='outlined' sx={loginButton} data-testid='login-button' onClick={login}>
          {t('buttonText.login')}
        </Button>
      )}
    </Box>
  );
}

export default AppBar;
